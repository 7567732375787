export const useRevealScratchcards = () => {
  const logger = useLogging()

  const { loading: isRevealAllLoading, mutate: revealAll } =
    useRevealScratchcardOrderMutation()

  const { loading: isRevealLoading, mutate: revealOne } =
    useRevealScratchcardMutation()

  const isLoading = computed(
    () => isRevealAllLoading.value || isRevealLoading.value,
  )

  const logError = (error: unknown, fallbackMessage: string) => {
    logger.log(
      error,
      'error',
      'useRevealScratchcards',
      'reveal_scratchcard_error',
      (error as Error)?.message || fallbackMessage,
    )
  }

  const revealAllTickets = async (orderId: string) => {
    if (isLoading.value) return null

    try {
      const response = await revealAll({ input: { orderId } })
      return response?.data?.revealOrder || null
    } catch (error) {
      logError(error, 'error occurred at revealAllTickets()')
      throw error
    }
  }

  const revealOneTicket = async (orderId: string, serialNumber: string) => {
    if (isLoading.value) return null

    try {
      const response = await revealOne({ input: { orderId, serialNumber } })
      return response?.data?.revealScratchcard.amount || null
    } catch (error) {
      logError(error, 'error occurred at revealOneTicket()')
      throw error
    }
  }

  return {
    isLoading,
    isRevealAllLoading,
    isRevealLoading,
    revealAll: revealAllTickets,
    revealOne: revealOneTicket,
  }
}
