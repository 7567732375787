export const useScratchcardScratchingModal = () => {
  const { currentRoute, replace } = useRouter()

  const serialNumber = computed(() => {
    const serialNumberValue = currentRoute.value.query.serialNumber
    return serialNumberValue ? String(serialNumberValue) : null
  })

  const close = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { scratch, serialNumber, ...query } = currentRoute.value.query
    await replace({ query })
  }

  const open = async (serialNumber: string) => {
    const currentQuery = currentRoute.value.query
    await replace({ query: { ...currentQuery, scratch: 'true', serialNumber } })
  }

  const setSerialNumber = async (serialNumber: string) => {
    if (!currentRoute.value.query.scratch) return
    await replace({ query: { ...currentRoute.value.query, serialNumber } })
  }

  return { close, open, serialNumber: readonly(serialNumber), setSerialNumber }
}
